import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import './PostList.scss'

export default class IndexPage extends React.Component {
  getThumbnail(post, cat) {
    if (cat === 'News') {
      return (
        <Img
          className="featured-image"
          fixed={
            post.acf.post_list_image
              ? post.acf.post_list_image.localFile.childImageSharp.fixed
              : post.featured_media.localFile.childImageSharp.fixed
          }
        />
      )
    }
    return (
      <Link to={`/${post.slug}`}>
        <Img
          className="featured-image"
          fixed={
            post.acf.post_list_image
              ? post.acf.post_list_image.localFile.childImageSharp.fixed
              : post.featured_media.localFile.childImageSharp.fixed
          }
        />
      </Link>
    )
  }

  getTitle(post, cat) {
    if (post.acf.remove_link_to_post === true) {
      return (
        <h3
          dangerouslySetInnerHTML={{
            __html: post.title,
          }}
        />
      )
    }
    if (post.acf.link_to) {
      return (
        <h3>
          <a href={post.acf.link_to} dangerouslySetInnerHTML={{
            __html: post.title,
          }}></a>
        </h3>
      )
    }
    return (
      <h3>
        <Link
          className="has-text-primary"
          to={`/${post.slug}`}
          dangerouslySetInnerHTML={{
            __html: post.title,
          }}
        />
      </h3>
    )
  }

  getContent(post, cat) {
    if (post.acf.category_display_type === 'full-post') {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: post.content,
          }}
        />
      )
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: post.excerpt.replace(/<p class="link-more.*/, ''),
        }}
      />
    )
  }

  getButton(post, cat) {
    if (post.acf.remove_link_to_post === true) {
      return null
    }
    if (post.acf.link_to) {
      return (
        <a className="button is-small pink read-more" href={post.acf.link_to}>Keep Reading →</a>
      )
    }
    return (
      <Link className="button is-small pink read-more" to={`/${post.slug}`}>
        Keep Reading →
      </Link>
    )
  }

  render() {
    const { posts, title } = this.props

    return (
      <div className="post-list content px-8">
        <h1 className="underlined">{title}</h1>
        <div className="pt-4">
          {posts.map(({ node: post }) => {
            const hasFeaturedImage = !!(
              (post.featured_media &&
                post.featured_media.localFile &&
                post.featured_media.localFile.childImageSharp) ||
              (post.acf.post_list_image &&
                post.acf.post_list_image.localFile &&
                post.acf.post_list_image.localFile.childImageSharp)
            )
            return (
              <div className="content single-post flex flex-wrap" key={post.id}>
                {hasFeaturedImage && (
                  <div className="thumbnail-wrapper md:w-2/12 lg:w-1/6">
                    {this.getThumbnail(post, title)}
                  </div>
                )}

                <div
                  className={`content-wrapper pr-2 md:pl-2 ${
                    hasFeaturedImage ? 'md:w-7/12 lg:w-2/3' : 'md:w-5/6'
                  }`}
                >
                  {this.getTitle(post, title)}
                  <p className="date">
                    <strong>{post.date}</strong>
                  </p>
                  {this.getContent(post, title)}
                </div>
                <div className="md:w-3/12 lg:w-1/6 text-right read-more-wrapper">
                  {this.getButton(post, title)}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    date(formatString: "DD MMMM YYYY")
    slug
  }
`
